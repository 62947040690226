import "~/lib/proxy-console-warn";
import "~/lib/turbo";
import "~/lib/jquery";
import "~/lib/nprogress";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "@splidejs/splide/dist/css/splide-core.min.css";
import "~/lib/app-namespace";
import "~/lib/stimulus";
import { installCurrencyInputs } from "~/lib/currency-input";
import { installCodemirror } from "~/lib/codemirror";

installCurrencyInputs();
installCodemirror();
